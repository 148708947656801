button {
  margin: 0;
  padding: 0;
  color: inherit;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
ul, li, p {
  margin: 0;
}