.data_box {
  position: absolute;
  top: 135px;
  left: calc(50% - 560px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 14px;
  width: 1120px;
  height: 98px;
  background-image: url(../../assets/img/bg_data_preview.png);
  background-size: cover;
  z-index: 2;
  &.data_box_sm {
    padding: 12px 80px 15px;
    top:88px;
    height: 80px;
    background-image: url(../../assets/img/bg-data-2x.png);
    background-size: 100% 100%;
    .data_item p {
      margin-bottom: 0;
      font-size: 20px;
    }
  }
  .data_item {
    position: relative;
    text-align: center;
    font-size: 16px;
   
    p {
      margin-bottom: 10px;
      font-size: 20px;
      font-family: Digiface;
      text-shadow: 0px 0px 12px rgba(228, 243, 255, 0.5);
    }
    span{
      font-size: 15px;
      font-weight: 700;
      // color: #00B4FF;
      color: white;
     
    }
    span:hover{
      /*font-size: 16px;*/
    }
    #red-num{
color: #ff0000;
    }
    
  }
  .data_item i{
    position: absolute;
    bottom: -15px;
    right: -10px;
    height: 76px;
    width:2px;
    background: linear-gradient(0deg, #60D0F2 0%, rgba(96, 208, 242, 0.1) 84%);
    transform:rotate(-20deg);
    
  }
  .data_item em{
    position: absolute;
    bottom: -15px;
    height: 76px;
    width:2px;
    left: -10px;
    background: linear-gradient(0deg, #60D0F2 0%, rgba(96, 208, 242, 0.1) 84%);
    transform:rotate(20deg);
  }
  .data_item.data_item_size{
   min-width: 160px;
  }
  .data_item.data_box_count{
    min-width: 160px;
    color: #FFD29B;
    P{
      font-size: 30px;
      line-height: 36px;
    }
    span{
      font-size: 16px;
      color: #FFD29B;
    }
  }  
  .warn_item{
    color: #ff0000;
  }
  .alarm {
    text-align: center;
    p {
      margin-bottom: 3px;
      line-height: 34px;
      font-size: 28px;
      font-family: Digiface;
      color: #F7225D;
      text-shadow: 0px 0px 12px #FF7097;
    }
    
    img {
      width: 24px;
      height: 27px;
    }
  }
}