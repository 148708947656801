.custom_select {
  color: inherit;
  .custom_select_arrow {
    margin-top: -2px;
    font-size: 18px;
    color: #fff;
  }
  :global {
    .ant-select-selector {
      z-index: 2;
    }
    .ant-select-arrow {
      z-index: 1;
    }
  }
}
.custom_select_dropdown {
  padding: 0;
  background-color: rgba(17, 71, 98, 0.95);
  border-radius: 2px;
  :global {
    .ant-select-item-option {
      &:first-of-type {
        padding-top: 16px;
      }
      &:last-of-type {
        padding-bottom: 16px;
      }
      padding: 8px 32px;
      font-size: inherit;
      color: #72CFED;
      background-color: transparent;
      &.ant-select-item-option-selected {
        color: #fff;
        background-color: rgba(21, 105, 139, 0.35);
      }
    }
  }
}