.search_input_panel_head {
  padding-right: 4px !important;
}
.panel_select {
  width: 100px;
  text-align: right;
  color: #24C7F9;
  :global {
    .ant-select-arrow span {
      color: #24C7F9;
    }
  }
}