.container {
    display: flex;
    padding: 10px 0;
    margin-top: 40px;
    .item {
        flex: 1;
        text-align: center;
        margin: 0 5px;
        margin-bottom: 20px;
        cursor: pointer;
         :hover {
            transform: scale(1.05)
        }
        &:nth-child(1) {
            .number {
                color: #86FFE0;
                text-shadow: 0 0 10px rgba(228, 243, 255, 0.5);
            }
            .label {
                color: #86FFE0;
                text-shadow: 0 0 10px rgba(228, 243, 255, 0.5);
            }
        }
        &:nth-child(2) {
            .number {
                color: #D59EFF;
                text-shadow: 0 0 10px #C780FE;
            }
            .label {
                text-shadow: 0 0 10px #C780FE;
                color: #D59EFF;
            }
        }
        .number {
            font-size: 12px;
            font-family: 'Digiface';
            margin-bottom: 10px;
        }
        .label {
            font-size: 12px;
            margin-bottom: 10px;
        }
        .img {
            position: relative;
            span {
                position: absolute;
                bottom: 12px;
                right: 5px;
                font-size: 12px;
                color: #00B4FF;
                line-height: 1.15;
            }
            img {
                height: 56px;
            }
            margin: 6px;
        }
    }
}

.allbtn {
    position: absolute;
    top: -26px;
    right: 0;
    border: 1px solid #20BCFC;
    color: #20BCFC;
    font-size: 14px;
    padding: 1px 10px;
    cursor: pointer;
}