.search_input_panel_head {
  padding-right: 4px !important;
}
.search_input {
  background-color: rgba(45, 171, 237, 0.5);
  input {
    font-size: 16px;
    color: #fff;
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    caret-color: #3788BA;
    &::placeholder {
      color: #EBEBEB;
    }
  }
  :global .ant-input-group-addon {
    background: transparent;
    button {
      padding: 0 8px;
      background: transparent;
      border: none;
      box-shadow: none;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}