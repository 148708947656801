
.alarm_modal {
  padding-top: 20px;
  padding-bottom: 14px;
  width: 100%;
  height: 100%;
  &.alarm_box_bg{
    //添加新样式
   // background: #fff;
    padding: 10px 4px 20px;
    clear: both;
    .alarm_box_contont{
     // background: #fff;
      padding: 10px;
      
      .alarm_box_title{
        text-align: center;
        font-size: 16px;
        color: #fff;
        margin: 0 auto;
        
      }
      .alarm_box_span{
        font-size: 14px;
        color: #20BCFC;
        border: #20BCFC;
        border-radius:4px;
        padding: 2px 8px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
  .alarm_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 16px;
    width: 100%;
    
    .alarm_item {
      width: 112px;
      text-align: center;
      &:disabled {
        cursor: default;
      }
      &:nth-of-type(n+4) {
        margin-top: 22px;
      }
      .alarm_icon {
        display: inline-block;
        padding: 2px;
        width: 44px;
        height: 44px;
        p {
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          font-weight: 600;
          color: #fff;
          background-image: url(../../assets/img/bg_fn.png);
          background-size: cover;
        }
        & + p {
          margin-top: 8px;
          line-height: 22px;
          font-size: 16px;
          color: #C0DCF8;
        }
      }
      &.alarm_item_red .alarm_icon p {
        background-image: url(../../assets/img/bg_fn_red.png);
      }
      &.alarm_item_selected .alarm_icon {
        background-image: url(../../assets/img/bg_fn_selected.png);
        background-size: cover;
      }
    }
  }
  &.alarm_modal_lg {
    padding: 7px;
    .alarm_box {
      padding-top: 35px;
      // background-image: url(../../assets/img/bg_alarm_top.png);
      // background-size: cover;
      .alarm_item {
        margin: 0;
        width: 100px;
        .alarm_icon + p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
.alarm_table {
  padding-left: 21px;
  color: #fff;
  .alarm_table_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .alarm_table_header {
    margin-top: 15px;
    padding-right: 25px;
    p {
      font-size: 16px;
    }
    button {
      width: 80px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      font-size: 16px;
      background: rgba(163, 223, 255, 0.45);
    }
  }
  .alarm_table_body {
    padding: 21px 20px 21px 0;
    .alarm_table_row {
      margin-bottom: 12px;
      font-size: 14px;
    }
    img {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
  .alarm_table_scrollbar {
    background-color: #5E94B1;
    border-radius: 3px;
    cursor: pointer;
  }

  
}
.list_box_contont{
  text-align: center;
 
}