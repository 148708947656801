:global {
    .inspector-feed-table .ant-table-tbody td {
        max-width: 200px;
        padding: 5px 5 5px 0;
    }
    .feed_table {
        background: #000;
        .ant-table-tbody td {
            &:first-of-type {
                padding-left: 0;
            }
            padding: 5px 0 5px 0;
            line-height: 20px;
            .ant-btn-text {
                padding: 0;
                border: none;
                height: 20px;
                line-height: 20px;
                img {
                    margin-top: -3px;
                    width: 24px;
                    height: 24px;
                }
            }
        }
        .ant-table-tbody .ant-table-row:nth-of-type(2n+2) td:first-of-type {
            padding-left: 0;
        }
        .ant-table-tbody .ant-table-row:nth-of-type(2n+1) {
            background-color: rgba(25, 105, 148, 0.5);
        }
    }
}