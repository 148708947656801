.alarm_close {
    margin: 11px;
    vertical-align: top;
    width: 14px;
    height: 14px;
}

.alarm_modal {
    top: calc(50% - 350x);
     :global {
        .ant-modal-close {
            margin-top: 12px;
            margin-right: 12px;
        }
        .ant-modal-close-x {
            width: 36px;
            height: 36px;
        }
        .ant-modal-content {
            padding: 55px 13px 14px 13px;
            height: 705px;
            background-color: transparent;
            background-image: url(~@/assets/img/bg_alarm_sm.png);
            background-size: cover;
            .ant-modal-body {
                position: relative;
                height: 100%;
                padding: 0;
                iframe {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.warn_list_box {
    width: 100%;
    height: 100%;
    .warn_tab_title {
        text-align: center;
        font-size: 24px;
        line-height: 48px;
        color: #fff;
        padding-top: 10px;
    }
    .warn_tale_box {
        padding: 10px 20px;
    }
}

:global {
    .topTable_custom_table__BnNRK .ant-table .ant-table-tbody td {
        max-width: 200px;
    }
    .ant-modal-footer {
        display: none;
    }
}

.pagelist {
    margin-top: 15px;
    text-align: right;
     :global {
        .ant-pagination-item {
            background-color: rgb(0 0 0 / 12%);
            border: 1px solid #24c7f9;
            a {
                color: #f5f5f5;
            }
        }
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
            border: 1px solid #24c7f9;
            background-color: rgb(0 0 0 / 12%);
        }
        .ant-pagination-prev button,
        .ant-pagination-next button {
            color: rgb(255 255 255);
            border-color: #24c7f9;
        }
        .ant-pagination-disabled .ant-pagination-item-link,
        .ant-pagination-disabled:hover .ant-pagination-item-link,
        .ant-pagination-disabled:focus .ant-pagination-item-link {
            color: rgb(255 255 255);
            border-color: #24c7f9;
        }
        .ant-pagination-item-active a {
            color: #ffffff;
        }
        .ant-pagination-item-active {
            background: #24c7f9;
            border-color: #24c7f9;
        }
        .ant-pagination-options {
            display: none;
        }
    }
}

.alarm_modal {
     :global {
        .ant-table {
            font-size: 10px;
            table {
                text-align: center;
                border: 1px solid #24C7F9;
                border-collapse: collapse;
            }
            th {
                border: 1px solid #24C7F9;
            }
            td {
                border: 1px solid #24C7F9;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            color: #fff;
            background-color: transparent;
            text-align: center;
            .ant-table-thead th {
                text-align: center;
                &:first-of-type {
                    padding-left: 0px;
                }
                padding:4px 0px 4px 0;
                // font-size: 16px;
                line-height: 25px;
                color: #24C7F9;
                background-color: transparent;
            }
            .ant-table-tbody td {
                &:first-of-type {
                    padding-left: 0px;
                }
                padding: 5px;
                line-height: 20px;
                .ant-btn-text {
                    padding: 0;
                    border: none;
                    height: 20px;
                    line-height: 20px;
                    img {
                        margin-top: -3px;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
            .ant-table-tbody .ant-table-row:nth-of-type(2n+1) {
                background-color: rgba(25, 105, 148, 0.5);
            }
            //@at-root
            // tr:nth-child(1){background-color: #f2f2f2;}
            .ant-table-tbody .ant-table-row:nth-of-type(1) {
                color: rgb(255, 1, 1);
            }
            .ant-table-tbody .ant-table-row:nth-of-type(2) {
                color: rgb(254, 180, 3);
            }
            .ant-table-tbody .ant-table-row:nth-of-type(2n+2) td {
                &:first-of-type {
                    padding-left: 0px;
                }
                // padding: 12px 20px 12px 0;
            }
            .ant-table-tbody tr.ant-table-row:hover td {
                background-color: transparent;
            }
        }
        // 自定义滚动条
        .ant-table-thead .ant-table-cell-scrollbar {
            display: none;
        }
        .ant-table-body {
            &::-webkit-scrollbar {
                width: 4px;
                box-shadow: -2px 0 2px rgba(17, 71, 98, 1);
            }
            &::-webkit-scrollbar-thumb {
                background: #5E94B1;
            }
            &::-webkit-scrollbar-track {
                background: #1B6A91;
            }
        }
        .ant-table-empty {
            .ant-table-cells {
                border: 0;
            }
        }
    }
}

.list_box_contont {
    text-align: center;
}