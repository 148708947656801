.search_input_panel_head {
  padding-right: 4px !important;
}
.panel_select {
  width: 100px;
  text-align: right;
  color: #24C7F9;
  :global {
    .ant-select-arrow span {
      color: #24C7F9;
    }
  }
}
.car_table {
  :global {
    .ant-table .ant-table-thead th {
      font-size: 14px;
    }
    .ant-table .ant-table-thead th:nth-of-type(n+3) {
      padding-right: 0;
    }
    .ant-table .ant-table-tbody .ant-table-row td:nth-of-type(n+3) {
      padding-right: 0;
    }
  }
}