.custom_table {
  :global {
    .ant-table {
      font-size: 10px;
      table{
        text-align: center;
       border: 1px solid #24C7F9 ;
       border-collapse: collapse;

      }
      th{
        border: 1px solid #24C7F9 ;
      }
      td{
        border: 1px solid #24C7F9 ;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      color: #fff;
      background-color: transparent;
      text-align: center;
      .ant-table-thead th{
        text-align: center;
        &:first-of-type {
          padding-left: 20px;
         
        }
        padding:4px 20px 4px 0;
       // font-size: 16px;
        line-height: 25px;
        color: #24C7F9;
        background-color: transparent;
      }

   
      .ant-table-tbody td {
        &:first-of-type {
          padding-left: 20px;
        
        }
        padding: 5px 20px 5px 0;
        line-height: 20px;
        .ant-btn-text {
          padding: 0;
          border: none;
          height: 20px;
          line-height: 20px;
          img {
            margin-top: -3px;
            width: 24px;
            height: 24px;
          }
        }
      }
      .ant-table-tbody .ant-table-row:nth-of-type(2n+1) {
        background-color: rgba(25, 105, 148, 0.5);
      
      }
      //@at-root
     // tr:nth-child(1){background-color: #f2f2f2;}
     .ant-table-tbody .ant-table-row:nth-of-type(1) {
      color: rgb(255, 1, 1);
    
    }
    .ant-table-tbody .ant-table-row:nth-of-type(2) {
      color: rgb(254,180,3);
    }
      .ant-table-tbody .ant-table-row:nth-of-type(2n+2) td {
        &:first-of-type {
          padding-left: 20px;
        }
        // padding: 12px 20px 12px 0;
      }
      .ant-table-tbody tr.ant-table-row:hover td {
        background-color: transparent;
      }
    }
    
    // 自定义滚动条
    .ant-table-thead .ant-table-cell-scrollbar {
      display: none;
    }
    .ant-table-body {
      &::-webkit-scrollbar {
        width: 4px;
        box-shadow: -2px 0 2px rgba(17, 71, 98, 1);
      }
      &::-webkit-scrollbar-thumb {
        background: #5E94B1;
      }
      &::-webkit-scrollbar-track {
        background: #1B6A91;
      }
    }
    .ant-table-empty{
      .ant-table-cells{
        border: 0;
      }
    }
  }
}
.nodata{
  color: #e6e6e6;
}

