.centerbg {
background-image: url(~@/assets/img/bgimg@2x.png);
height: 920px;
background-size:100% 100%;
-moz-background-size:100% 100%;
-webkit-background-size:100% 100%;
display: flex;
align-items: center;
justify-content: center;

}
.center_box_img{
    width: 800px;
    height: 700px;
    background-image: url(~@/assets/img/center/border@2x.png);
    background-size:100% 100%;
    -moz-background-size:100% 100%;
    -webkit-background-size:100% 100%;
        img{
            width: 800px;
            height:700px;
            }
    }
    .hide{
        display: none;
    }