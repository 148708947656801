body {
    color: #fff;
}

.app {
    position: relative;
    width: 100vw;
    min-width: 1920px;
    height: 100vh;
    /* min-height: 1080px; */
    background-color: #0A2A4D;
    overflow: hidden;
}

.app.app_sm {
    min-width: auto;
}

.app-wide {
    width: 3472px;
}

#thing-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.hide {
    display: none;
}

.shadow::after,
.shadow::before {
    content: '';
    position: absolute;
    width: 670px;
    z-index: 0;
    height: 100%;
    display: block;
    pointer-events: none;
}

.shadow::before {
    left: 0;
    background: linear-gradient(to right, #031C36, rgb(3 28 54 / 92%), rgb(3 28 54 / 58%), rgba(10, 41, 76, 0));
}

.shadow::after {
    right: 0;
    background: linear-gradient(to left, #031C36, rgb(3 28 54 / 92%), rgb(3 28 54 / 58%), rgba(10, 41, 76, 0));
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.cursor-pointer {
    cursor: pointer;
    color: #fff;
}

.cursor-pointe :hover {
    transform: scale(1.1);
}

.search_bg {
    background-image: url(./assets/img/header/search-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: calc(100vw - 81.4vw);
    max-width: 1140px;
    height: 32px;
    z-index: 9999;
    float: left;
    margin-left: -1.2vw;
    padding-left: 2vw;
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 58px;
    /*box-shadow: 0 0 8px 0 rgba(45, 86, 150, 1);*/
    z-index: 2;
    padding: calc(0.5vw) 0;
    margin-right: -1.3vw;
}