.abtn_box{
.btn_diamond {
    width: 152px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    color: #fff;
    background-size: cover;
    display: block;
   
  }
  .btn_bagcolor{
    background: linear-gradient(
      90deg
      , #1ca3ea, #dfecec0d);
  }
}