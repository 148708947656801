.container {
  padding: 0px 3px 0;
  height: 100%;
  position: relative;
  .tabs {
    background: rgba(163, 223, 255, 0.2);
    display: flex;
    .item {
      flex: 1;
      padding: 3px 0;
      text-align: center;
      font-size: 14px;
      color: #1AB7E8;
      position: relative;
      cursor: pointer;
      &.active {
        color: #fff;
        &::after {
          content: '';
          display: block;
          height: 1px;
          background: #fff;
          width: 49px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
.legend {
  position: absolute;
  right: 30px;
  top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // height: 100%;
  width: 100px;
  color: #fff;
  .legend_item {
    position: relative;
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    margin: 8px 0;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -32px;
      top: 4px;
      width: 24px;
      height: 12px;
    }
    &:nth-of-type(1)::before {
      background-color: #FEC849;
    }
    &:nth-of-type(2)::before {
      background-color: #315AFA;
    }
    &:nth-of-type(3)::before {
      background-color:#00A8FE;
    }
    &:nth-of-type(4)::before {
      background-color:#66CE97;
    }
    &:nth-of-type(5)::before {
      background-color: #3E77D4;
    }
  }
}