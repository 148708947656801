.info_window_close {
    margin: 6px;
    vertical-align: top;
    width: 16px;
    height: 16px;
}

.info_window {
    top: calc(50% - 250px);
    color: #fff;
     :global {
        .ant-modal-close-x {
            width: 28px;
            height: 28px;
        }
        .ant-modal-content {
            background-color: rgba(17, 71, 98, 0.9);
            border: 2px solid rgba(67, 167, 229, 0.86);
            border-radius: 6px;
            .ant-modal-body {
                padding: 0;
            }
        }
    }
}

.info_window_title {
    // padding-left: 11px;
    line-height: 36px;
    font-size: 16px;
    text-align: center;
    font-family: Source Han Sans CN;
    background: linear-gradient(0deg, #2e7284, #304e6d);
    // background-image: url(../../assets/img/bg_arrow_left.png);
    // background-size: 7px 11px;
    // background-position: 0 50%;
    // background-repeat: no-repeat;
}

.info_window_alarm {
    margin-top: 8px;
    .info_window_alarm_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            font-size: 16px;
            color: #FFB743;
        }
        button {
            width: 68px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            font-size: 12px;
            background: rgba(163, 223, 255, 0.45);
            border-radius: 2px;
        }
    }
    .info_window_alarm_list {
        padding: 11px 0;
        font-size: 12px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.35);
        div:not(:last-of-type) {
            margin-bottom: 7px;
        }
    }
}

.info_window_main {
    // padding: 12px 0;
    font-size: 12px;
    line-height: 30px;
    .info_window_item_title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }
    p {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .info_window_subtitle {
        text-align: center;
        color: #48CCFE;
        font-size: 14px;
        display: block;
    }
}

.inspectorTrailbtn {
    width: 70px;
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    background: #5087a4;
    display: inline-block;
    cursor: pointer;
    float: right;
}

.info_window_layout {
    background: linear-gradient(0deg, #274353, #172e3c);
    padding: 5px 20px;
}

.info_window_layout1 {
    background: linear-gradient(0deg, #323b65, #1e2b44);
    padding: 5px 20px;
}

.info_window_layout2 {
    background: linear-gradient(0deg, #403968, #221d46);
    padding: 5px 20px;
}

.info_window_item {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.info_window_footer {
    padding: 10px 0;
    text-align: center;
    button {
        width: 80px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        font-size: 12px;
        background: rgba(163, 223, 255, 0.45);
        border-radius: 2px;
        margin: 0 10px;
        img {
            margin-top: 3px;
            vertical-align: top;
            margin-right: 8px;
            width: 16px;
            height: 16px;
        }
    }
}

.info_window_parklot {
    background: url('../../assets/img/parklot_btn_bg.png') no-repeat;
    background-size: 100% 100%;
    width: 124px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-size: 12px;
    margin: 20px auto;
}