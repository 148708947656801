.fetch_container {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    &.lg {
        .top {
            // padding-top: 25px;
            // padding-bottom: 45px;
        }
        .bottom {
            padding-bottom: 50px;
        }
    }
    .top {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        padding: 10px;
        .item {
            .out,
            .mid,
            .inner {
                width: 60px;
                height: 60px;
                position: absolute;
                // top: 10px;
                background-size: contain;
                // margin: 0 30px;
            }
            .out {
                animation: rotate 2s linear infinite;
            }
            .mid {
                animation: rotate 1.6s linear infinite reverse;
            }
            .inner {
                animation: rotate 1s linear infinite;
            }
            &.red,
            &.red1 {
                .number {
                    background-image: url(~@/assets/img/warning_circles/red_bg.png);
                }
                .out {
                    background-image: url(~@/assets/img/warning_circles/red_out.png);
                }
                .mid {
                    background-image: url(~@/assets/img/warning_circles/red_mid.png);
                }
                .inner {
                    background-image: url(~@/assets/img/warning_circles/red_inner.png);
                }
            }
            &.yellow {
                .number {
                    background-image: url(~@/assets/img/warning_circles/yellow_bg.png);
                }
                .out {
                    background-image: url(~@/assets/img/warning_circles/yellow_out.png);
                }
                .mid {
                    background-image: url(~@/assets/img/warning_circles/yellow_mid.png);
                }
                .inner {
                    background-image: url(~@/assets/img/warning_circles/yellow_inner.png);
                }
            }
            &.yellow1 {
                .number {
                    background-image: url(~@/assets/img/warning_circles/yellow_bg.png);
                }
                .out {
                    background-image: url(~@/assets/img/warning_circles/yellow_out.png);
                }
                .mid {
                    background-image: url(~@/assets/img/warning_circles/yellow_mid.png);
                }
                .inner {
                    background-image: url(~@/assets/img/warning_circles/yellow_inner.png);
                }
            }
            &.green {
                .number {
                    background-image: url(~@/assets/img/warning_circles/green_bg.png);
                }
                .out {
                    background-image: url(~@/assets/img/warning_circles/green_out.png);
                }
                .mid {
                    background-image: url(~@/assets/img/warning_circles/green_mid.png);
                }
                .inner {
                    background-image: url(~@/assets/img/warning_circles/green_inner.png);
                }
            }
            &.green1 {
                .number {
                    background-image: url(~@/assets/img/warning_circles/green_bg.png);
                }
                .out {
                    background-image: url(~@/assets/img/warning_circles/green_out.png);
                }
                .mid {
                    background-image: url(~@/assets/img/warning_circles/green_mid.png);
                }
                .inner {
                    background-image: url(~@/assets/img/warning_circles/green_inner.png);
                }
            }
            .number {
                width: 70px;
                height: 70px;
                color: #fff;
                font-size: 30px;
                font-family: 'Digiface';
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                background-size: contain;
                margin: 0 20px;
                &.plus {
                    &::after {
                        content: '';
                        width: 15px;
                        height: 10px;
                        position: absolute;
                        bottom: 15px;
                        background-image: url(~@/assets/img/icon_plus.png);
                        background-size: cover;
                    }
                }
            }
            .label {
                color: #fff;
                font-size: 14px;
                text-align: center;
            }
        }
    }
    .bottom {
        display: flex;
        justify-content: space-between;
        padding: 0px 20px 30px 20px;
        .item {
            position: relative;
            &:disabled {
                cursor: default;
            }
            .number {
                width: 30px;
                height: 30px;
                background-image: url(~@/assets/img/bg_fn.png);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 22px;
                color: #fff;
                background-size: contain;
                background-repeat: no-repeat;
                &.warning {
                    background-image: url(~@/assets/img/bg_fn_red.png);
                }
            }
            .label {
                position: absolute;
                left: 50%;
                transform: translateX(-50%) translateY(100%);
                font-size: 12px;
                color: #fff;
                bottom: -10px;
                white-space: nowrap;
            }
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}