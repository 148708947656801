.container {
  padding: 0 0 6px 0;

}
.park_row {
  display: flex;
  justify-content: space-around;
  &:nth-child(1) {
    padding: 0 50px;
    margin-bottom: 10px;
  }
}
.fetch_container{
  background-image: url(../../../assets/img/tingchenan/tingchenanwangge_.png) norepeat;
}
.parking_item {
  .label {
    font-size: 12px;
    color: #fff;
    text-shadow: 0px 0px 4px #FFFFFF;
    text-align: center;
  }
  .bottom {
    font-size: 12px;
    color: #fff;
    text-align: center;
    p {
      margin-bottom: 0;
    }
  }
}
.parkinglotmore{
  position: absolute;
  top: -40px;
  right: 0;
  border: 1px solid #20BCFC;
  color: #20BCFC;
  font-size: 14px;
  padding: 1px 10px;
  cursor: pointer;
}
.parkinglotttag{
  position: absolute;
  top: -40px;
  right:60px;
  border: 1px solid #20BCFC;
  color: #20BCFC;
  font-size: 14px;
  padding: 1px 10px;
  cursor: pointer;
}
.table {
  .head {
    display: flex;
    padding: 12px 10px;
    text-align: center;
    .item {
      flex: 0.5;
      color: #24C7F9;
      font-weight: bold;
      font-size: 12px;
     
    }
    .flex2{
      flex: 1.5;
    }
    .flex1{
      flex: 0.8;
    }
  }
  .body {
    height: 216px;
    overflow-y: auto;

    &::-webkit-scrollbar {/*滚动条整体样式*/
      width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
   }

    &::-webkit-scrollbar-thumb {/*滚动条里面小方块*/

          border-radius: 4px;
          -webkit-box-shadow: inset 0 0 2px rgba(36,199,249, 0.5);
          background: #24C7F9;

      }

      &::-webkit-scrollbar-track {/*滚动条里面轨道*/
          -webkit-box-shadow: inset 0 0 2px rgba(36,199,249,0.5);
          border-radius: 4px;
          background: #234375;

    }
    .row {
      display: flex;
      padding: 12px 10px;
      text-align: center;
      background: linear-gradient(135deg, rgba(25, 105, 148, 0.5) 0%, rgba(33, 129, 179, 0.06) 100%, #050E17 100%);
      &:nth-child(2n) {
        background: transparent;
      }
      .item {
        flex: 0.5;
        color: #fff;
        font-size: 12px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 135px;
        overflow: hidden;
      }
      .flex2{
        flex: 1.5;
      }
      .flex1{
        flex: 0.8;
      }
    }
  }
}

.container {
  padding: 8px 0;
  
}

:global {
  .ant-carousel .slick-dots-bottom{
    bottom: 0;
  }
  .ant-carousel .slick-dots{
    bottom: 0;
  }
  .ant-carousel .slick-next{
    right: 0;
  }
  .ant-carousel .slick-prev{
    left: 0;
  }
  .ant-carousel .slick-dots{
    bottom: -14px;
  }
}

.row {
  display: flex;
  justify-content: space-around;
  &:nth-child(1) {
    padding: 0 50px;
    margin-bottom: 10px;
  }
}
.parking_item {
  width: 120px;
  .label {
    color: #fff;
    font-size: 12px;
    text-shadow: 0px 0px 4px #FFFFFF;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 120px;
    overflow: hidden;
    margin-bottom: 6px;
  }
  .animation_icon {
    width: 120px;
    height: 100px;
    position: relative;
    .parking_icon, .shape1, .shape2, .base {
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      left: 50%;
      transform: translateX(-50%);
    }
    .parking_icon {
      background-image: url(~@/assets/img/parking/icon_p.png);
      width: 30px;
      height: 40px;
      bottom: 55px;
      z-index: 2;
      animation: flash 3s linear infinite;
    }
    .shape1 {
      background-image: url(~@/assets/img/parking/shape1.png);
      width: 70px;
      height: 40px;
      bottom: 46px;
      z-index: 1;
      opacity: 0;
      animation: shape 3s ease-out infinite;
    }
    .shape2 {
      background-image: url(~@/assets/img/parking/shape2.png);
      width: 70px;
      height: 40px;
      bottom: 35px;
      z-index: 1;
      animation: shape2 3s ease-out infinite;
    }
    .base {
      width: 110px;
      height: 90px;
      bottom: 0px;
      background-image: url(~@/assets/img/parking/base.png);
    }
  }
  .tips {
    font-size: 12px;
    text-align: center;
    color: #fff;
    line-height: 1.15;
    margin-top: 6px;

  }
}
.nodata{
  color: #e6e6e6;
}
