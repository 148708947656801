.alarm_close {
    margin: 11px;
    vertical-align: top;
    width: 14px;
    height: 14px;
}

.alarm_modal {
    top: calc(50% - 350x);
     :global {
        .ant-modal-close {
            margin-top: 12px;
            margin-right: 12px;
        }
        .ant-modal-close-x {
            width: 36px;
            height: 36px;
        }
        .ant-modal-content {
            padding: 55px 13px 14px 13px;
            height: 705px;
            background-color: transparent;
            background-image: url(~@/assets/img/bg_alarm_sm.png);
            background-size: cover;
            .ant-modal-body {
                position: relative;
                height: 100%;
                padding: 0;
                iframe {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.imglist {
    width: 100%;
    height: 100%;
    background: #eee;
    overflow: scroll;
    ul {
        display: flex;
        // flex-direction:column;
        // flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        padding: 0;
        height: 100%;
        overflow: hidden;
        li {
            padding: 10px;
            list-style: none;
            height: 100%;
            display: flex;
            align-items: center;
            width: 400px;
        }
        .imgitem4 {
            width: 260px;
            float: left;
            height: 310px;
        }
    }
    .boxitem4 {
        display: block;
    }
}

:golbal {
    .ant-image {
        height: 100%;
        overflow: hidden;
        display: contents;
        img.ant-image-img {
            max-height: 100%;
            width: auto;
        }
    }
}

.warn_list_box {
    width: 100%;
    height: 100%;
    .warn_tab_title {
        text-align: center;
        font-size: 24px;
        line-height: 48px;
        color: #fff;
        padding-top: 10px;
    }
    .warn_tale_box {
        text-align: center;
        padding: 20px;
    }
}

.warn_box {
    width: 100%;
    height: 100%;
}

.pagelist {
    margin-top: 15px;
    text-align: right;
     :global {
        .ant-pagination-item {
            background-color: rgb(0 0 0 / 12%);
            border: 1px solid #24c7f9;
            a {
                color: #f5f5f5;
            }
        }
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
            border: 1px solid #24c7f9;
            background-color: rgb(0 0 0 / 12%);
        }
        .ant-pagination-prev button,
        .ant-pagination-next button {
            color: rgb(255 255 255);
            border-color: #24c7f9;
        }
        .ant-pagination-disabled .ant-pagination-item-link,
        .ant-pagination-disabled:hover .ant-pagination-item-link,
        .ant-pagination-disabled:focus .ant-pagination-item-link {
            color: rgb(255 255 255);
            border-color: #24c7f9;
        }
        .ant-pagination-item-active a {
            color: #ffffff;
        }
        .ant-pagination-item-active {
            background: #24c7f9;
            border-color: #24c7f9;
        }
        .ant-pagination-options {
            display: none;
        }
    }
}