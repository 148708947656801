.animation_icon {
  width: 120px;
  height: 46px;
  position: relative;
  margin-bottom: -4px;
  .parking_icon, .shape1, .shape2, .base {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    left: 50%;
    transform: translateX(-50%);
  }
  .parking_icon {
    background-image: url(~@/assets/img/parking/icon_p.png);
    width: 15px;
    height: 20px;
    bottom: 35px;
    z-index: 2;
    animation: parkingAnimate 3s ease-out infinite;
  }
  .shape1 {
    background-image: url(~@/assets/img/parking/shape1.png);
    width: 30px;
    height: 20px;
    bottom: 26px;
    z-index: 1;
    opacity: 0;
    animation: shape 3s ease-out infinite;
  }
  .shape2 {
    background-image: url(~@/assets/img/parking/shape2.png);
    width: 30px;
    height: 20px;
    bottom: 15px;
    z-index: 1;
    animation: shape2 3s ease-out infinite;
  }
  .base {
    width: 45px;
    height: 40px;
    bottom: 0px;
    background-image: url(~@/assets/img/parking/base.png);
    
  }
}


@keyframes shape {
  0% {
    opacity: 0;
    transform: translateY(10px) translateX(-50%);
  }
  20% {
    opacity: 0;
    transform: translateY(10px) translateX(-50%);
  }
  70% {
    opacity: 1;
    transform: translateY(0px) translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translateY(0px) translateX(-50%);
  }
}
@keyframes shape2 {
  0% {
    opacity: 0;
    transform: translateY(10px) translateX(-50%);
  }
  70% {
    opacity: 1;
    transform: translateY(0px) translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translateY(0px) translateX(-50%);
  }
}

@keyframes parkingAnimate {
  0% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
  40% {
    opacity: 1;
  }
  65% {
    opacity: 0.7;
    transform: translateY(10px) translateX(-50%);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}