.trend_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 51px;
  height: 280px;
  color: #fff;
  .trend_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
    .trend_title {
      margin-bottom: 15px;
      font-size: 16px;
    }
    .trend_info {
      margin-top: 26px;
      font-size: 12px;
    }
  }
}