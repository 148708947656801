.inspector-rate-table{
  padding-right: 4px !important;
  overflow: hidden;
}
.parkinglotmore{
  position: absolute;
  top: -38px;
  right: 0;
  border: 1px solid #20BCFC;
  color: #20BCFC;
  font-size: 14px;
  padding: 1px 10px;
  cursor: pointer;
}

.parkinglotttag{
  position: absolute;
  top: -38px;
  right:60px;
  border: 1px solid #20BCFC;
  color: #20BCFC;
  font-size: 14px;
  padding: 1px 10px;
  cursor: pointer;
}
:global{
  .topTable_custom .ant-table table{
    border: 0;
  }
  .ant-table-body{
    overflow-x: hidden;
    margin-top: -1px;
    .ant-table-body table{
      border: 0
    }
  }

  .CustomTable_custom_table{
    .ant-table-body table{
      border: 0
    }
  }
}