.picture_window_close {
  margin: 10px;
  vertical-align: top;
  width: 16px;
  height: 16px;
}
.picture_info {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  padding-bottom: 12px;
  color: #fff;
  border-bottom: 1px solid rgba(255,255,255,0.35);
  div {
    margin-top: 8px;
    width: 50%;
  }
}
.picture_window {
  top: 50%;
  transform: translateY(-50%);
  :global {
    .ant-modal-close-x {
      width: 36px;
      height: 36px;
    }
    .ant-modal-content {
      padding: 12px;
      background-color: rgba(17,71,98,0.9);
      border: 2px solid rgba(67, 167, 229, 0.86);
      border-radius: 6px;
      overflow: hidden;
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}
.nopicture_text{
  font-size: 16px;
  text-align: center;
  margin: 20px auto;
  color: #fff;
}