.wave_box {
  display: flex;
  flex-wrap: wrap;
  .wave_box_title {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    line-height: 28px;
    font-size: 20px;
    color: #fff;
    text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.5);
  }
  .wave_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 34px 0 41px 0;
    width: 25%;
    > div:first-of-type {
      width: 88px;
      border-radius: 50%;
      box-shadow: 0px 0px 12px 4px rgba(193, 237, 255, 0.2);
    }
    .wave_item_label {
      margin-top: 39px;
      line-height: 20px;
      color: #fff;
    }
  }
}