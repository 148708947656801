.bottom_menu {
  position: absolute;
  // left: calc(50% - 350px);
  left: 50%;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0 1px;
 /* width: 825px;*/
  background-color: rgba(31, 111, 154, 0.75);

  border-right: 1px solid #2DABED;
  z-index: 2;
  background-image: url(../../assets/img/bottom-bg.png) ;
  background-repeat: no-repeat;
  background-size:100% 100%;
  transform: translateX(-50%);
  -moz-background-size:100% 100%;
  -webkit-background-size:100% 100%;
  
}
.menu_item {
  width: 136px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;;

  border-right: 1px solid #2DABED;
  a{
    color: #ffffff;
    font-weight: 500;;
  }
  &.menu_item_selected {
    background-image: url(../../assets/img/bg_btn_selected.png);
    background-size: cover;
    cursor: default;
    border: none;
  }
}