.container {
  padding: 8px 0;
  
}

:global {
  .ant-carousel .slick-dots-bottom{
    bottom: 0;
  }
  .ant-carousel .slick-dots{
    bottom: 0;
  }
  .ant-carousel .slick-next{
    right: 0;
  }
  .ant-carousel .slick-prev{
    left: 0;
  }
}
.parkinglotmore{
  position: absolute;
  top: -28px;
  right: 0;
  border: 1px solid #20BCFC;
  color: #20BCFC;
  font-size: 14px;
  padding: 1px 10px;
  cursor: pointer;
}
.parkinglotttag{
  position: absolute;
  top: -28px;
  right:60px;
  border: 1px solid #20BCFC;
  color: #20BCFC;
  font-size: 14px;
  padding: 1px 10px;
  cursor: pointer;
}
.row {
  display: flex;
  justify-content: space-around;
  &:nth-child(1) {
    padding: 0 50px;
    margin-bottom: 10px;
  }
}
.parking_item {
  width: 120px;
  .label {
    color: #fff;
    font-size: 12px;
    text-shadow: 0px 0px 4px #FFFFFF;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 120px;
    overflow: hidden;
  }
  .animation_icon {
    width: 120px;
    height: 100px;
    position: relative;
    .parking_icon, .shape1, .shape2, .base {
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      left: 50%;
      transform: translateX(-50%);
    }
    .parking_icon {
      background-image: url(~@/assets/img/parking/icon_p.png);
      width: 30px;
      height: 40px;
      bottom: 55px;
      z-index: 2;
      animation: flash 3s linear infinite;
    }
    .shape1 {
      background-image: url(~@/assets/img/parking/shape1.png);
      width: 70px;
      height: 40px;
      bottom: 46px;
      z-index: 1;
      opacity: 0;
      animation: shape 3s ease-out infinite;
    }
    .shape2 {
      background-image: url(~@/assets/img/parking/shape2.png);
      width: 70px;
      height: 40px;
      bottom: 35px;
      z-index: 1;
      animation: shape2 3s ease-out infinite;
    }
    .base {
      width: 110px;
      height: 90px;
      bottom: 0px;
      background-image: url(~@/assets/img/parking/base.png);
    }
  }
  .tips {
    font-size: 12px;
    text-align: center;
    color: #fff;
    line-height: 1.15;

  }
}
.nodata{
  color: #e6e6e6;
}
