.container {
  &::after {
    content: '';
    clear: both;
  }
  .tabs {
    // background: rgba(163, 223, 255, 0.2);
    width: 100px;
    float: left;
    margin-top: 20px;
    .item {
      flex: 1;
      padding: 3px 0;
     
      position: relative;
      cursor: pointer;
      z-index: 1;
      color: #fff;
      p{
        text-align: center;
        font-size: 14px;
       
        z-index: 2;
        position: relative;
      }
      &.active {
       // text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.8);
        color: #0C1E44;
        &::after {
          content: '';
          display: block;
          // height: 2px;
          // background: #fff;
          width: 100%;
          position: absolute;
          bottom: 0;
          height: 100%;
          background: linear-gradient(90deg, #006AE1, #16B7FF);
          border-radius: 4px;
          z-index: 0;
        }
      }
    }
  }
}
.box{
  width: 320px;
  // right: 0;
  // position: absolute;
  // bottom: 0;
  float: right;
}