.panel {
  width: 440px;
 //background: linear-gradient(135deg, rgba(25, 105, 148, 0.35) 0%, rgba(33, 129, 179, 0.35) 100%, #050E17 100%);
//  background-image: url(~@/assets/img/panel_bg.png);
//  background-repeat: no-repeat;
//  background-size:100% 100%;
//  -moz-background-size:100% 100%;
// -webkit-background-size:100% 100%;
  animation: rotate 1.6s linear infinite reverse;
  // height: 310px;
  position: relative;
  .head {
    height: 40px;
    position: absolute;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0 24px;
    font-size: 16px;
   // background: linear-gradient(93deg, rgba(21, 105, 139, 0.5) 0%, rgba(39, 86, 135, 0) 100%);
    &:after {
      content: '';
      display: block;
      width: 26px;
      height: 26px;
      background: #2DABED;
      border: solid 2px #205E88;
      box-sizing: border-box;
      position: absolute;
      top: -13px;
      left: -13px;
      transform: rotate(45deg);
    }
    .content {
     
      color: #fff;
     
    }
  }
  .head_bg{
    &:after {
      display: none;
    }
    padding:0;
    margin: 15px 0 0 20px;
    height: 33px;
    overflow: initial;
    .content {
    color: #24C7F9;
    // padding: 0 4px;
    font-size: 16px;
    font-weight: bold;
    background:  url(~@/assets/img/titlebg2.png) repeat-x;

    height: 33px;
    line-height: 31px;
    }
    .bg_left{
      position:absolute;
      left:-12px;
      top: 0;
      height: 33px;
      width: 12px;
      background:  url(~@/assets/img/titlebg1.png) no-repeat;
    } 
    .bg_right{
      position:absolute;
      top: 0;
      right: -92px;
      height:33px;
      width: 92px;
      background:  url(~@/assets/img/titlebg3.png) no-repeat;
    }                     
                        // background: url(~@/assets/img/titlebg1.png) left  100% no-repeat, url(~@/assets/img/titlebg2.png)   center 100% repeat, 
                        // url(~@/assets/img/titlebg3.png) right 100% no-repeat;
  }
  :global(.fetch_container) {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imgClass{
    position: absolute;
    width: 440px;
    height: 320px;
    z-index: -1;
   
  }
  .tableClass{
    // padding-top: 60px;
   margin: 0 auto;
    bottom: 8px;
    left: 8px;
    width: calc(100% - 20px);
    position: absolute;
    // height: calc(100% - 80px);
  }
}
.panelH{
  height: 310px;
  
}
.abs_render_box{
  position: absolute;
  right: 5px;
  top: 13px;
  width: 420px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .abs_render_left{
    cursor: pointer;
    width: 200px;
    height: 46px;
    line-height: 46px;
  }
.abs_render_right{
 
  cursor: pointer;
}

}