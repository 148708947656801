.alarm_close {
  margin: 11px;
  vertical-align: top;
  width: 14px;
  height: 14px;
}
.alarm_modal {
  top: calc(50% - 160px);
  :global {
    .ant-modal-close{
      top: 6px;
      right: 6px;
    }
    .ant-modal-close-x {
      width: 36px;
      height: 36px;
    }
    .ant-modal-content {
      height: 235px;
      background-color: transparent;
      background-image: url(../../../../assets/img/bg_alarm_sm.png);
      background-size: 100% 100%;
      .ant-modal-body {
        height: 100%;
        padding: 0;
      }
    }
  }
  &.alarm_modal_lg {
    top: calc(50% - 170px);
    :global .ant-modal-content {
      height: 317px;
      background-image: url(../../../../assets/img/bg_alarm_lg.png);
    }
  }
  &.alarm_modal_bg {
    top: calc(50% - 270px);
    :global .ant-modal-content {
      height: 517px;
      background-image: url(../../../../assets/img/bg_alarm_lg.png);
    }
  }
}