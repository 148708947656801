.ball_container {
  position: relative;
  height: 150px;
  .ball {
    width: 100px;
    height: 100px;
    background: url(~@/assets/img/bg_ball.png);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition: all .3s ease-out;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 0;
    animation: fadeIn .6s ease-out forwards 1.6s;

    &:global(.index_0) {
      transform: translateY(160px) translateX(260px) scale(1);
      text-shadow: 0px 0px 10px #002E35;
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 120px;
        height: 120px;
        background: url(~@/assets/img/bg_ball.png);
        opacity: 0.5;
        left: -10px;
        top: -10px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &:global(.index_1) {
      transform: translateY(70px) translateX(325px) scale(0.8);
    }
    &:global(.index_2) {
      transform: translateY(0px) translateX(240px) scale(0.6);
    }
    &:global(.index_3) {
      transform: translateY(-10px) translateX(120px) scale(0.6);
    }
    &:global(.index_4) {
      transform: translateY(40px) translateX(0px) scale(0.7);
    }
    &:global(.index_5) {
      transform: translateY(140px) translateX(40px) scale(0.8);
    }
    .label {
      font-size: 16px;
      color: #fff;
    }
    .value {
      font-size: 14px;
      color: #fff;
    }
  }
  .center_ball {
    width: 160px;
    height: 160px;
    position: absolute;
    background: url(~@/assets/img/center_ball.png);
    background-repeat: no-repeat;
    background-size: contain;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    animation: fadeIn .6s ease-out forwards 1.6s;
    .number,.label {
      font-size: 16px;
      color: #fff;
      text-shadow: 0px 0px 10px #002E35;
    }
  }
}
.bottom {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  position: relative;
  transform-style: preserve-3d;
  perspective: 500px;
  .source {
    position: absolute;
    bottom: 50px;
    width: 30px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
    }
  }
  .light_up {
    position: absolute;
    bottom: 50px;
    width: 170px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation: fadeIn .3s ease-out forwards 1.3s;
    img {
      width: 100%;
      animation: flashLight 2s ease-out infinite;
    }
  }
  .circles {
    position: absolute;
    width: 200px;
    height: 100px;
    bottom: 40px;
    .circle_img {
      position: absolute;
      opacity: 0;
      animation: fadeIn .3s ease-out forwards;
      img {
        width: 100%;
      }
      &:nth-child(1) {
        bottom: 7px;
        left: 50%;
        transform: translateX(-50%);
        width: 80px;
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: .3s;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        width: 140px;
      }
      &:nth-child(3) {
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
        width: 180px;
        animation-delay: .5s;
      }
      &:nth-child(4) {
        bottom: -15px;
        left: 50%;
        transform: translateX(-50%);
        width: 230px;
        animation-delay: .6s;
      }
    }
    .light {
      position: absolute;
      bottom: -30px;
      width: 250px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      animation: fadeIn .6s ease-out forwards 1s;
      img {
        width: 100%;
        animation: flashLight 2s ease-out infinite;
      }
    }
  }

  .center {
    position: absolute;
    width: 20px;
    height: 20px;
    background: #86E8E4;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) rotateX(70deg) translateZ(30px);
  }
  .circle {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transform-style: preserve-3d;
    transform-origin: center center;
    perspective: 500px;
    opacity: 0;
    animation: scaleIn .6s ease-out forwards 1s;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border: dashed 6px #86E8E4;
      border-radius: 50%;
      transform: rotateX(70deg);
    }
    &:nth-child(1) {
      width: 80px;
      height: 80px;
      &::after {
        transform: rotateX(70deg) translateZ(0px);
      }
    }
    &:nth-child(2) {
      width: 100px;
      height: 100px;
      &::after {
        transform: rotateX(70deg) translateZ(0px);
      }
    }
    &:nth-child(3) {
      width: 140px;
      height: 140px;
    }
    &:nth-child(4) {
      width: 180px;
      height: 180px;
      &::after {
        border-width: 10px;
        border-color: #73C4BB;
        transform: rotateX(70deg) translateZ(-30px);
      }
    }
  }
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flashLight {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}