.street_view {
  top: 50%;
  transform: translateY(-50%);
  :global {
    .ant-modal-close-x {
      width: 32px;
      height: 32px;
    }
    .ant-modal-content {
      background-color: transparent;
    }
    .ant-modal-body {
      padding: 12px;
      color: #fff;
      background-color: rgba(17,71,98,0.89);
      border: 4px solid #43A7E5;
      border-radius: 4px;
    }
  }
  .street_view_info {
    margin-bottom: 4px;
  }
}
.window_close {
  margin: 9px;
  vertical-align: top;
  width: 14px;
  height: 14px;
}