.block_container {
  position: fixed;
  top: calc(50% - 364px);
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  height: auto;
  bottom: calc(50% - 446px);
  // bottom: 40px;
  &.left {
    left: 20px;
    z-index: 2;
  }
  
  &.right {
    right: 20px;
  }
  &.center{
   /* left: calc(50% - 460px);*/
    width: 1850px;
    min-height: 820px;
    z-index: 1;
    display: none;
  }

  .item {
    margin-bottom: 3%;
    // height: 32.3%;
    height: 264px;
    flex: 1;
    &:last-child{
      margin: 0;
    }
    &.item_box2{
      flex: 2;
    }
  }
}