.street_view {
  top: 50%;
  transform: translateY(-50%);
  :global {
    .ant-modal-close-x {
      width: 32px;
      height: 32px;
    }
    .ant-modal-content {
      background-color: transparent;
      // &::after {
      //   content: "";
      //   display: block;
      //   position: absolute;
      //   bottom: -17px;
      //   left: 104px;
      //   width: 52px;
      //   height: 21px;
      //   background-image: url(../../assets/img/box_bottom_arrow.png);
      //   background-size: cover;
      //   z-index: 1;
      // }
    }
    .ant-modal-body {
      padding: 12px;
      color: #fff;
      background-color: rgba(17,71,98,0.89);
      border: 4px solid #43A7E5;
      border-radius: 4px;
    }
  }
  .street_view_info {
    margin-bottom: 4px;
  }
  .street_view_car {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    img {
      width: 16px;
      cursor: pointer;
    }
  }
  .street_view_alarm {
    margin-top: 12px;
    padding: 12px 0;
    color: #FF6363;
    border-top: 1px solid rgba(255,255,255,0.34);
  }
}
.window_close {
  margin: 9px;
  vertical-align: top;
  width: 14px;
  height: 14px;
}