.pillar {
  position: relative;
  width: 66px;
  background-size: cover;
  &.pillar_level_1 {
    height: 98px;
    background-image: url(~@/assets/img/pillars/pillar_1.png);
  }
  &.pillar_level_2 {
    height: 72px;
    background-image: url(~@/assets/img/pillars/pillar_2.png);
  }
  &.pillar_level_3 {
    height: 48px;
    background-image: url(~@/assets/img/pillars/pillar_3.png);
  }
  .spred {
    position: absolute;
    background-size: cover;
    &.spred_1 {
      bottom: 7px;
      left: 12.5px;
      width: 40px;
      height: 19px;
      background-image: url(~@/assets/img/pillars/spred_1.png);
      z-index: -1;
      animation: spred1 6s ease-in-out infinite;
    }
    &.spred_2 {
      bottom: -5px;
      left: -11px;
      width: 88px;
      height: 40px;
      background-image: url(~@/assets/img/pillars/spred_2.png);
      z-index: -1;
      animation: spred2 6s ease-in-out infinite;
    }
    &.spred_3 {
      bottom: -14px;
      left: -28px;
      width: 122px;
      height: 58px;
      background-image: url(~@/assets/img/pillars/spred_1.png);
      z-index: -1;
      animation: spred3 6s ease-in-out infinite;
    }
  }
}

@keyframes spred1 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spred2 {
  0% {
    opacity: 0;
  }
  17% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  83% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spred3 {
  0% {
    opacity: 0;
  }
  // 33% {
  //   opacity: 0;
  // }
  27% {
    opacity: 0;
  }
  42% {
    opacity: 1;
  }
  58% {
    opacity: 1;
  }
  73% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}