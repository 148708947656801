.container {
  // padding-bottom: 17px;
  .head {
    display: grid;
    grid-template-columns: 140px 140px;
    grid-template-rows: repeat(2, 24px);
    column-gap: 10px;
    row-gap: 8px;
    grid-auto-flow: column dense;
    margin: 0 auto;
    width: 420px;
    margin-top: 18px;
    .total {
      border-top: solid 2px #2DABED;
      grid-row-start: 1;
      grid-row-end: 4;
      background: linear-gradient(to bottom, rgba(45, 171, 237, 0.4) 0%, rgba(17, 87, 124, 0.24) 100%);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
     
      .icon {
        width: 49px;
        height: 49px;
        img {
          width: 100%;
        }
      }
      .number {
        font-size: 16px;
        color: #FFD571;
        position: relative;
        text-align: center;
        .data {
          font-size: 38px;
          font-family: 'Digiface';
        }
        .unit {
         
        }
      }
      .label {
        font-size: 12px;
        color: #fff;
      }
    }
    .item {
      font-size: 14px;
      background: linear-gradient(135deg, rgba(25, 105, 148, 0.5) 0%, rgba(33, 129, 179, 0.06) 100%, #050E17 100%);
      display: flex;
      align-items: center;
      padding: 0 8px;
      color: #FFFFFF;
      justify-content: space-evenly;
      .label {
       
        flex: 1;
        padding-right: 10px;
      }
      .value {
        color: #FFB643;
      }
    }
  }
}

.charts {
  margin: 12px 12px 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
  .item {
    .bars {
      display: flex;
      justify-content: space-around;
      padding: 0 20px;
    }
    .label {
      margin-top: 7px;
     
      text-align: center;
    }
    .item_name{
      text-align: center;
      line-height: 22px;
      height: 22px;
    }
  }
}